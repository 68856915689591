import * as React from 'react';
import '../styles/grid.css';

import '../styles/styles.css';
import Layout from '../components/Layout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import HeaderAboutUs from '../components/HeaderAboutUs';
import { StaticImage } from 'gatsby-plugin-image';

export default function AboutUs (): React.ReactElement {

  return (
    <Layout>
      <div className='imageWrapper clipPathBottom'>
        <HeaderAboutUs />
      </div>
      <div className='container' >
        <div className='row'>
          <div className='flex visionMission'>
            <div className='col-6'>
              <h1>Unsere Mission und Vision</h1>
              <p>Prozesse zu digitalisieren treibt uns an und das findet sich auch in unserem Namen, der wörtlich von <b>Trice</b>, aus dem Englischen für <b>im Nu</b> und <b>Onic</b> aus Electronic als <b>Sinnbild für das Digitale</b> stammt.
                <br />
                <br />
                Wir sind <b>Innovatoren für Strategieumsetzung</b> und ermöglichen unseren Kunden genug <b>operativen Grip</b> zu entwickeln, um Ihre Strategien auch <b>auf die Straße zu bringen</b>.
              </p>
            </div>
            <div className='col-6'>
              <StaticImage src='../images/golden-circle.svg' alt='Unsere Mission und Vision' placeholder='none' />
            </div>
          </div>
        </div>
      </div>

      <div className='eyeCatcher clipPathTop'>
        <div className='container eyeCatcherCentered'>
          <div className='row'>
            <div className='col-12'>
              <p>Wir haben ein Werkzeug geschaffen, das sich einfach in die bestehende Systemlandschaft integriert,die Lücke zwischen den Ebenen ausfüllt und so die verschiedenen Flughöhen miteinander verbindet.</p>
            </div>
          </div>
        </div>
      </div>

      <div className='container imageText'>
        <div className='row'>
          <div className='col-4'>
            <StaticImage src='../images/Tanzer-Christoph-Triceonic.jpg' alt={'Christoph Tanzer'} placeholder='none' />
          </div>
          <div className='col-8'><h3>Christoph Tanzer COO</h3>
            <p>War viele Jahre als Serviceleiter und Operationsmanager in der Consumer Eleketronik Industrie tätig.<br />
              Allrounder, Problemlöser und Organisator. Oldtimer Schrauber und Italienfan.</p>
            <div className='col-12 buttonsAbout'>
              <a href='tel:+437203712391750'>
                <button className='darkButton'>
                  <FontAwesomeIcon className='fontAwesomeIcon' icon={faPhone} />
                  Jetzt anrufen!
                </button>
              </a>
              <a href='mailto:christoph.tanzer@triceonic.com'>
                <button className='lightButton'>
                  <FontAwesomeIcon className='fontAwesomeIcon' icon={faEnvelope} />
                  E-Mail schreiben!
                </button>
              </a>
              <a href='https://www.linkedin.com/in/christoph-tanzer/' target='_blank'>
                <button className='lightButton linkedInLink'>
                  LinkedIn
                </button>
              </a>
            </div>
          </div>
        </div>

        <div className='container imageText'>
          <div className='row'>
            <div className='col-4'>
              <StaticImage src='../images/Wiesmair-Martin-Triceonic.jpg' alt={'Martin Wiesmair'} placeholder='none' />
            </div>
            <div className='col-8'>
              <h3>Martin Wiesmair CEO</h3>
              <p>Verantwortete als Softwareengineer und Systemarchitekt einen Technologiebereich bei einem großen Maschinenhersteller.<br />
                Prozessdenker, Systemanalytiker und Visionär. Sportkletterer und Mongol Rallye Co-Pilot.</p>

              <div className='col-12 buttonsAbout'>
                <a href='tel:+437203712392359'>
                  <button className='darkButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faPhone} />
                    Jetzt anrufen!
                  </button>
                </a>
                <a href='mailto:martin.wiesmair@triceonic.com'>
                  <button className='lightButton'>
                    <FontAwesomeIcon className='fontAwesomeIcon' icon={faEnvelope} />
                    E-Mail schreiben!
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='eyeCatcher clipPathTop'>
        <div className='container eyeCatcherCentered'>
          <div className='row'>
            <div className='col-12'>
              <p>
                Es hat einige Innovationen gebraucht, aber wir konnten uns den Wunsch nach einer mobilen Anwendung endlich erfüllen, mit der ohne großen Aufwand die Aufgaben Priorisierung aktuell gehalten werden kann.</p>
            </div>
          </div>
        </div>
      </div>
    </Layout >
  );
}
